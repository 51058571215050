import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Card, CircularProgress, List, ListItem, IconButton, ListItemText, ListItemIcon, Box } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InfoIcon from '@mui/icons-material/Info';
import axiosWithAuth from '../sections/auth/login/axiosWithAuth';
import YearEndReportCard from '../sections/@dashboard/reports/YearEndReportCard';

interface FileData {
  name: string;
  timeCreated: string; // Use the correct type for your date/time format
}


export default function ReportsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<FileData[]>([]);
  const [orgID, setOrgID] = React.useState('');

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const orgIDRes = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/orgID`);
        setOrgID(orgIDRes.data.orgID);

        const res = await axiosWithAuth().get<FileData[]>(`${process.env.REACT_APP_BACKEND_URL}/api/reportsList`);
        // Filter out directory placeholders before setting state
        const filteredFiles = res.data.filter((file: FileData) => !file.name.endsWith('/'));
        setFiles(filteredFiles);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        setIsLoading(false);
      }
    };
  
    fetchFiles();
  }, []);
  

  const handleDownload = async (fileName: string) => {
    try {
      const response = await axiosWithAuth().get(`${process.env.REACT_APP_BACKEND_URL}/api/reportDownloadLink?file=${fileName}`);
      const signedUrl = response.data.signedUrl;
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error downloading file', error);
    }
  };

  const formatFileName = (fileName: string) => {
    return fileName.split('/').pop();
  };

  if (isLoading) {
    return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
    </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Reports | Ask Lilac</title>
      </Helmet>
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Reports
        </Typography>

        <YearEndReportCard orgID={orgID} />

        <Card sx={{ p: 2, width: '100%', maxHeight: '70vh', overflow: 'auto' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Monthly Reports</Typography>
          {files.length > 0 ? (
            <List>
              {files.map((file) => (
                <ListItem key={file.name} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <ListItemIcon>
                    <FolderOpenIcon />
                  </ListItemIcon>
                  <ListItemText primary={formatFileName(file.name)} />
                  <IconButton onClick={() => handleDownload(file.name)} color="primary">
                    <DownloadIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              <InfoIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              No reports available.
            </Typography>
          )}
        </Card>
      </Container>
    </>
  );
}
