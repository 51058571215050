import React from 'react';
import { Card, Typography, Box, Link, Stack } from '@mui/material';
import { Award, LineChart, Star } from 'lucide-react';

interface YearEndReportCardProps {
  orgID: any;
}

const YearEndReportCard: React.FC<YearEndReportCardProps> = ({ orgID }) => {
  if (orgID !== 14) {
    return null;
  }

  return (
    <Card sx={{ mb: 4, p: 3 }}>
      <Typography variant="h5" sx={{ color: 'primary.main', fontWeight: 'bold', mb: 1 }}>
        2024 Year in Review 🎉
      </Typography>
      
      <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
        Discover how your school's community used Ask Lilac in 2024
      </Typography>

      <Stack spacing={2} sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Award size={20} />
          <Typography variant="body1">Achievements</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <LineChart size={20} />
          <Typography variant="body1">Analytics</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Star size={20} />
          <Typography variant="body1">Highlights</Typography>
        </Box>
      </Stack>

      <Link 
        href="https://tysal24.asklilac.com/?key=champlainrocks"
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          display: 'flex', 
          alignItems: 'center',
          color: 'primary.main',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
          View Interactive Report
        </Typography>
        <Box component="svg" 
          sx={{ ml: 1, width: 20, height: 20 }}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </Box>
      </Link>
    </Card>
  );
};

export default YearEndReportCard;

